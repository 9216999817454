<script>
import LabeledInput from '@/components/form/LabeledInput';
import Checkbox from '@/components/form/Checkbox';
import TLS from '../tls';
import SimpleSecretSelector from '@/components/form/SimpleSecretSelector';
import { _VIEW } from '@/config/query-params';

export default {
  components: {
    Checkbox, LabeledInput, SimpleSecretSelector, TLS
  },
  props:      {
    mode: {
      type:     String,
      required: true,
    },
    value: {
      type:     Object,
      required: true
    },
    namespace: {
      type:     String,
      default:  ''
    }
  },
  data() {
    this.$set(this.value, 'sendResolved', this.value.sendResolved || false);
    this.$set(this.value, 'requireTls', this.value.requireTls || false);

    return {
      view:                          _VIEW,
      initialAuthPasswordSecretName:  this.value?.authPassword?.name ? this.value.authPassword.name : '',
      initialAuthPasswordSecretKey:  this.value.authPassword?.key ? this.value.authPassword.key : ''
    };
  },

  methods: {
    updateAuthPasswordSecretName(name) {
      const existingKey = this.value.authPassword?.key || '';

      if (this.value.authPassword) {
        this.value.authPassword = {
          key: existingKey,
          name
        };
      } else {
        this.value['authPassword'] = {
          key: '',
          name
        };
      }
    },
    updateAuthPasswordSecretKey(key) {
      const existingName = this.value.authPassword?.name || '';

      if (this.value.authPassword) {
        this.value.authPassword = {
          name: existingName,
          key
        };
      } else {
        this.value['authPassword'] = {
          name: '',
          key
        };
      }
    }
  }
};
</script>

<template>
  <div>
    <div class="row">
      <div class="col span-12">
        <h3>Target</h3>
      </div>
    </div>
    <div class="row mb-20">
      <div class="col span-6">
        <LabeledInput v-model="value.to" :mode="mode" label="Default Recipient Address" placeholder="e.g. admin@example.com" />
      </div>
      <div class="col span-6">
        <Checkbox v-model="value.sendResolved" :mode="mode" class="mt-20" label="Enable send resolved alerts" />
      </div>
    </div>
    <h2 class="mb-10">
      SMTP
    </h2>
    <div class="row mb-20">
      <div class="col span-12">
        <LabeledInput v-model="value.from" :mode="mode" label="Sender" placeholder="e.g. admin@example.com" />
      </div>
    </div>
    <div class="row mb-20">
      <div class="col span-6">
        <LabeledInput v-model="value.smarthost" :mode="mode" label="Host" placeholder="e.g. 192.168.1.121:587" />
      </div>
      <div class="col span-6">
        <Checkbox v-model="value.requireTls" :mode="mode" class="mt-20" label="Use TLS" />
      </div>
    </div>
    <div v-if="namespace" class="row mb-20">
      <div class="col span-6">
        <LabeledInput v-model="value.authUsername" :mode="mode" :label="t('monitoring.alertmanagerConfig.email.username')" placeholder="e.g. John" />
      </div>
    </div>
    <div class="row mb-20">
      <SimpleSecretSelector
        v-if="namespace"
        :initial-key="initialAuthPasswordSecretKey"
        :mode="mode"
        :initial-name="initialAuthPasswordSecretName"
        :namespace="namespace"
        :disabled="mode === view"
        :secret-name-label="t('monitoring.alertmanagerConfig.email.password')"
        @updateSecretName="updateAuthPasswordSecretName"
        @updateSecretKey="updateAuthPasswordSecretKey"
      />
      <Banner v-else color="error">
        {{ t('alertmanagerConfigReceiver.namespaceWarning') }}
      </Banner>
    </div>
    <TLS v-model="value" class="mb-20" :mode="mode" :namespace="namespace" />
  </div>
</template>
